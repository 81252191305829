<template>
<div>
  <el-row>
    <el-col>
      <el-form :inline='true'>

        <el-form-item label="">
          <el-input v-model="filters.payment" @input="(e) => (filters.payment = utils.validForbid(e))" clearable
                    placeholder="付款客户"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select style="width: 150px;" v-model="filters.projectMatch" placeholder="项目匹配状态" clearable>
            <el-option
                v-for="item in objectToMatch"
                :key="item.id"
                :label="item.name"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select style="width: 150px;" v-model="filters.billMatch" placeholder="发票匹配状态" clearable>
            <el-option
                v-for="item in billToMatch"
                :key="item.id"
                :label="item.name"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-date-picker
              v-model="filters.filingEndDate"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              style="width: 220px;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
<!--  列表-->
  <el-table
      :data="objectDataList"
      border
      style="width: 100%"
      v-loading="listLoading"
      ref="multipleTable"
      @current-change='currentChange'
      :highlight-current-row='true'
      :cell-style="rowClass"
  >
    <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
    <!-- <el-table-column type="selection" width="45"></el-table-column> -->
    <el-table-column prop="payerAccountName" label="付款客户" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="payerAccountNo" label="付款账户" width="215" align="center" show-overflow-tooltip></el-table-column>

    <el-table-column prop="tranFlowNo" label="交易流水号" width="215" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="amount" label="付款金额" width="120" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="" label="待匹配金额" width="120" align="center" show-overflow-tooltip>
      <template #default='scope'>
        {{scope.row.amount-scope.row.verificationAmount}}
      </template>
    </el-table-column>

    <el-table-column prop="tranDate" label="交易时间" width="120" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="voucherType" label="凭证种类" width="125" align="center" show-overflow-tooltip></el-table-column>

    <el-table-column prop="accountDate" label="汇账日期" width="120" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="verificationStatus" width="120" label="项目匹配状态" align="center" show-overflow-tooltip>
      <template slot-scope='scope'>
        <el-tag v-if="scope.row.verificationStatus == 0" type="danger">未匹配</el-tag>
        <el-tag v-if="scope.row.verificationStatus == 1" type="success">部分匹配</el-tag>
        <el-tag v-if="scope.row.verificationStatus == 2" >全匹配</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="invoiceStatus" label="发票匹配状态" width="120" align="center" show-overflow-tooltip>
      <template slot-scope='scope'>
        <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>
        <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>
        <el-tag v-if="scope.row.invoiceStatus == 2" >全匹配</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="bankRemark2" label="备注二" width="120" align="center" show-overflow-tooltip></el-table-column>
  </el-table>
  <!--底部工具条-->
  <el-col :span="24" class="pageBar">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="pages.pageArr"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataCount">
    </el-pagination>
  </el-col>
  <!--发票匹配-->
  <el-dialog title="匹配发票" :visible.sync="dialog.matchInvoiceDialog.show" v-model="dialog.matchInvoiceDialog.show"
             :close-on-click-modal="false" width='80%'>
<!--    <el-table-->
<!--        :data="invoiceMatchList"-->
<!--        border-->
<!--        style="width: 100%"-->
<!--        v-loading="listLoading"-->
<!--        ref="multipleTable"-->
<!--        @current-change='currentInvoiceChange'-->
<!--        :highlight-current-row='true'-->
<!--        :cell-style="rowClass"-->
<!--    >-->
<!--      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>-->
<!--      <el-table-column prop="issuerType" label="开票类型" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 0" >个人</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 1" type="success">企业</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="invoiceTpye" label="发票类型" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="invoiceMoney" label="开票金额" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceTitle" label="发票抬头" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="externalPersonPhone" label="手机号" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="openTime" label="开票时间" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceStatus" label="收款匹配状态" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 2" >全匹配</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
    <el-form :model="invoiceFormMore"  ref="addFormRef" label-width="160px"
             style='min-width:800px;height:500px;'>
      <el-form-item label="发票匹配:" >
        <el-select v-model="invoiceFormMore.invoiceId"
                   placeholder="请输入匹配金额"
                   filterable
                   clearable
                   @change="currentInvoiceChange"
        >
          <el-option
              v-for="item in invoiceMatchList"
              :key="item.id"
              :label="item.invoicePin"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
      <div slot="footer" class="dialog-footer">
      <el-button @click.native="invoiceClosedDialog">取消</el-button>
      <el-button type="primary" @click="addSubmitInvoice" :loading="addLoading">确认</el-button>
    </div>
  </el-dialog>
  <!--项目匹配-->
  <el-dialog title="匹配项目" :visible.sync="dialog.matchObjectDialog.show" v-model="dialog.matchObjectDialog.show"
             :close-on-click-modal="false" width='80%'>
<!--    <el-table-->
<!--        :data="objectMatchList"-->
<!--        border-->
<!--        style="width: 100%"-->
<!--        v-loading="listLoading"-->
<!--        ref="multipleTable"-->
<!--        @current-change='currentObjectChange'-->
<!--        :highlight-current-row='true'-->
<!--        :cell-style="rowClass"-->
<!--    >-->
<!--      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>-->
<!--      <el-table-column prop="issuerType" label="省" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceTpye" label="县" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceMoney" label="合同编号" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceTitle" label="项目名称" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceFax" label="应收款（项目金额）" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="externalPersonPhone" label="已匹配金额" align="center" show-overflow-tooltip></el-table-column>-->
<!--      <el-table-column prop="invoiceStatus" label="收款匹配状态" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceStatus == 2">全匹配</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="invoiceFax" label="发票匹配状态" align="center" show-overflow-tooltip>-->
<!--        <template slot-scope='scope'>-->
<!--          <el-tag v-if="scope.row.invoiceFax == 0" type="danger">未匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceFax == 1" type="success">部分匹配</el-tag>-->
<!--          <el-tag v-if="scope.row.invoiceFax == 2">全匹配</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
    <el-form :model="objectFormMore" :rules="objectFormMoreRules" ref="objectFormMoreRef" label-width="160px"
             style='min-width:800px;height:500px;'>
      <el-form-item label="项目匹配:">

        <el-select
          v-model="objectFormMore.objectId"
          placeholder="请输入匹配项目"
          filterable
          clearable
          @change="currentObjectChange"
        >
            <el-option
              v-for="item in objectMatchList"
              :key="item.id"
              :label="item.objectPin"
              :value="item.id"
              width="200px"
            >
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="匹配金额:" prop="matchAmount">
        <el-input  v-model="objectFormMore.matchAmount" placeholder="匹配金额"
                  style='width:200px'></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click.native="objectClosedDialog">取消</el-button>
      <el-button type="primary" @click="addSubmitObject" :loading="addLoading">确认</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import util from "../../../util/date";
import { getButtonList} from '@/promissionRouter'
import {
  getIncomePaymentList,
  getInvoiceList,
  getUnMatchInvoiceList,
  getProjectMatchList,
  matchRevenuePaymentByInvoice, matchRevenuePaymentByProject
} from '@/api/api'


export default {
  components: { Toolbar },
  data(){
    return{
      utils:'',
      dataCount:undefined,
      //关于分页的obj
      pages: {
        pageSize: 15,//默认每页条数
        pageArr: [15, 30, 45, 60],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 200, //默认总页数
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
      },
      //发票状态
      billToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      //项目状态
      objectToMatch: [
        { name: '未匹配', value: 0 },
        { name: '部分匹配', value: 1 },
        { name: '全部匹配', value: 2 }
      ],
      //筛选
      filters: {
        payment: '',//付款客户
        projectMatch: '',//项目匹配状态
        billMatch: '',//发票匹配状态
        filingEndDate: undefined,
      },
      objectComplete:{},//列表接口数据
      objectDataList:[],//列表数组
      listLoading:false,
      addLoading: false,
      buttonList: [],//按钮事件列表
      needData:undefined,
      dialog:{
        //匹配项目是否弹出
        matchObjectDialog:{
          show:false
        },
        //匹配发票是否弹出
        matchInvoiceDialog:{
          show:false
        },
      },
      objectMatchList:[],//项目匹配
      invoiceMatchList:[],//发票匹配
      needInvoiceData:undefined,//发票列表某一行
      needObjectData:undefined,//项目列表某一行
      paymentName: '',
      revenuePaymentData:undefined,
      invoiceAll:[],//发票明细拼接
      invoiceFormMore:{
        invoiceId:undefined,
        matchAmount:undefined,
      },
      objectFormMoreRules:{
        matchAmount:[{ required: true, message: '请输入金额', trigger: 'blur' },
          { validator: this.validateMatchAmount, trigger: 'blur' }
        ],

      },
      objectFormMore: {
        objectId: undefined,
        matchAmount:undefined,
      },
      unNeedData:{},
      moneyPush:undefined,
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.init();
  },
  methods:{
    clearReceipt () {
      this.getListData()
    },
    validateMatchAmount (rule, value, callback) {
      if (this.needObjectData[0] && value > this.moneyPush ) {
        callback(new Error('金额不能大于'+ this.moneyPush));
      } else {
        callback();
      }
    },
    //获取列表数据
    getDataList(){
      // console.log(this.filters.filingEndDate, 'data')
      let params = {
        invoiceStatus:this.filters.billMatch,//发票匹配状态
        pageIndex:this.pages.pageIndex,//页码
        pageSize:this.pages.pageSize,//每页条数
        payerAccountName: this.filters.payment ? this.filters.payment : undefined,//付款客户
        tranEndDate:this.filters.filingEndDate? this.filters.filingEndDate[1]:undefined,//交易结束时间
        tranStartDate: this.filters.filingEndDate? this.filters.filingEndDate[0] :undefined,//交易开始时间
        verificationStatus: this.filters.projectMatch,//项目匹配状态
      }
      getIncomePaymentList(params).then(res=>{
        let result = res.data
        if (result.success){
          const response = result.response
          this.dataCount = response.dataCount
          const data = response.data
          // console.log(result,'result')
          this.objectDataList = data
          this.objectComplete = response
        }
      })
    },
    // 查询
    getListData () {
      this.pages.pageIndex = 1
      this.getDataList();
    },
    //按钮事件
    callFunction (item) {
      this[item.func].apply(this, item);
    },
    // 点击table某一行
    currentChange (val) {
      this.needData = val
    },
    //点击发票某一行
    currentInvoiceChange (val) {
      let object = this.invoiceMatchList.filter(item => item.id === val)
      console.log(object,'fapiao')
      this.needInvoiceData = object
    },
    //点击项目某一行
    currentObjectChange (val) {
      console.log(val,'val')
      if (val){
        this.needObjectData=undefined
        const list = this.objectMatchList
        let object = list.filter(item => item.id === val)
        this.needObjectData = object
        console.log(object, 'valObject')
        this.moneyPush = +((object[0].projectTotalPrice-object[0].verificationAmount).toFixed(2))
        this.objectFormMore.matchAmount=undefined
        this.$refs.objectFormMoreRef.clearValidate();
      }else {
        this.objectFormMore.objectId=undefined
      }
    },
    //关闭发票界面
    invoiceClosedDialog(){
      this.dialog.matchInvoiceDialog.show = false
      this.invoiceFormMore.invoiceId = undefined;
      this.needInvoiceData = undefined
    },
    //关闭项目界面
    objectClosedDialog () {
      this.dialog.matchObjectDialog.show = false
      this.objectFormMore.objectId = undefined;
      this.objectFormMore.matchAmount = undefined;
      this.needObjectData = undefined
      this.objectMatchList=[]
    },
    //匹配发票
    matchInvoice () {
      if (this.needData === undefined || !this.needData.id) {
        this.$message({
          message: '请选择一条数据',
          type: "error"
        });
        return
      }
      // console.log(this.needData,'needData')
      let params = {
        pageIndex:1,//页码
        pageSize:15,//每页条数
      }
      getUnMatchInvoiceList(params).then(res=>{
        let result = res.data;
        if (result.success){
          const response = result.response
          const data = response.data
          data.map(item => {
            let invoiceForm = {
              issuerType: item ? item.issuerType : undefined,//开票类型
              invoiceTpye: item ? item.invoiceTpye : undefined,//发票类型
              invoiceMoney: item ? item.invoiceMoney : undefined,//开票金额
              invoiceTitle: item ? item.invoiceTitle : undefined,//发票抬头
              invoiceFax: item ? item.invoiceFax : undefined,//购方税号
              externalPersonPhone: item ? item.externalPersonPhone : undefined,//手机号
              externalPersonEmail: item ? item.externalPersonEmail : undefined,//邮箱
              openTime: item ? item.openTime : undefined,//开票时间
              invoiceStatus: item ? item.invoiceStatus : undefined,//收款状态
              invoiceVerificationAmount:item ? item.invoiceVerificationAmount : undefined ,//已开票金额
              invoiceNo:item ? item.invoiceNo : undefined,//发票号
              invoiceBankAccount:item ? item.invoiceBankAccount :undefined ,//购买方名称
            }
            if (item.issuerType === 0) {
              invoiceForm.issuerType = '个人'
            }
            if (item.issuerType === 1) {
              invoiceForm.issuerType = '企业'
            }
            if (item.invoiceTpye === 0) {
              invoiceForm.invoiceTpye = '全电专票'
            }
            if (item.invoiceVerificationAmount === ''){
              invoiceForm.invoiceVerificationAmount = 0
            }
            // if (item.verificationStatus === 0) {
            //   invoiceForm.verificationStatus = '未匹配'
            // }
            // if (item.verificationStatus === 1) {
            //   invoiceForm.verificationStatus = '部分匹配'
            // }
            // if (item.verificationStatus === 2) {
            //   invoiceForm.verificationStatus = '全匹配'
            // }
            const aaa = invoiceForm.invoiceMoney-invoiceForm.invoiceVerificationAmount
            const kaiXing = '购买方名称：' + invoiceForm.invoiceBankAccount + ' ，'
            const faXing = '待开票金额：'  + aaa + '，'
            const kaiE = '开票金额：' + invoiceForm.invoiceMoney + '，'
            const faTou = '发票抬头：' + invoiceForm.invoiceTitle + '，'
            const gouHao = '购方税号：' + invoiceForm.invoiceFax + '，'
            const fapiao = '发票号：' + invoiceForm.invoiceNo + '，'
            const phone = '手机号：' + invoiceForm.externalPersonPhone + '，'
            const email = '邮箱：' + invoiceForm.externalPersonEmail + '，'
            const kaiPiao = '开票时间：' + invoiceForm.openTime.split('T')[0]
            // const shouKuan = '收款匹配状态：' + invoiceForm.verificationStatus
            const all = fapiao + ' ' + faXing +' ' + kaiXing + ' ' + kaiE +' ' + faTou +' ' + gouHao + ' ' + phone + ' ' + email + ' ' + kaiPiao
            item.invoicePin = all
          })
          console.log(data)
          this.invoiceMatchList = data
        }
      })
      this.dialog.matchInvoiceDialog.show = true;
    },
    //上传匹配发票
    addSubmitInvoice(){
      if (this.needInvoiceData[0] === undefined || !this.needInvoiceData[0].id) {
        this.$message({
          message: '请选择一条数据',
          type: "error"
        });
        return
      }
      let params = {
        invoiceId:this.invoiceFormMore.invoiceId,//发票ID
        matchAmount:(+this.needInvoiceData[0].invoiceMoney)*100,//匹配金额
        revenuePaymentsId:this.needData.id//收入凭证账单ID
      }
      if (this.needInvoiceData[0].id){
        this.addLoading = true
        matchRevenuePaymentByInvoice(params).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: "success"
            });
            this.addLoading = false
            this.getDataList();

           this.invoiceClosedDialog()
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
            this.addLoading = false
          }
        })
      }
    },
    //获取匹配项目数据
    async objectData(item){
       const  res = await getProjectMatchList(item)
        let result = res.data;
        if (result.status===200) {
          const response = result.response
          const data = response.data
          data.map(item => {
            let invoiceForm = {
              provinceName: item ? item.provinceName : undefined,//省
              areaName: item ? item.areaName : undefined,//县（区）
              contractBn: item ? item.contractBn : undefined,//合同编号
              projectName: item ? item.projectName : undefined,//项目名称
              projectTotalPrice: item ? item.projectTotalPrice : undefined,//应收款（项目金额）
              verificationAmount: item ? item.verificationAmount : undefined,//已匹配金额
              // verificationStatus: item ? item.verificationStatus : undefined,//收款匹配状态
              // invoiceStatus: item ? item.invoiceStatus : undefined,//发票匹配状态
            }
            if (item.verificationStatus === 0) {
              invoiceForm.verificationStatus = '未匹配'
            }
            if (item.verificationStatus === 1) {
              invoiceForm.verificationStatus = '部分匹配'
            }
            if (item.verificationStatus === 2) {
              invoiceForm.verificationStatus = '全匹配'
            }
            if (item.invoiceStatus === 0) {
              invoiceForm.invoiceStatus = '未匹配'
            }
            if (item.invoiceStatus === 1) {
              invoiceForm.invoiceStatus = '部分匹配'
            }
            if (item.invoiceStatus === 2) {
              invoiceForm.invoiceStatus = '全匹配'
            }
            const kaiXing = '省：' + invoiceForm.provinceName + ','
            const faXing = '县（区）：' + invoiceForm.areaName + ','
            const kaiE = '合同编号：' + invoiceForm.contractBn + ','
            const faTou = '项目名称：' + invoiceForm.projectName + ','
            const gouHao = '应收款（项目金额）：' + invoiceForm.projectTotalPrice + ','
            const phone = '已匹配金额：' + invoiceForm.verificationAmount + ','
            const email = '收款匹配状态：' + invoiceForm.verificationStatus + ','
            const kaiPiao = '发票匹配状态：' + invoiceForm.invoiceStatus + ','
            const all = kaiXing + faXing + kaiE + faTou + gouHao + phone + email + kaiPiao
            item.objectPin = all
          })
          this.objectMatchList.push(...data)
        }

    },
    //匹配项目
    matchObject () {
      if (this.needData === undefined || !this.needData.id) {
        this.$message({
          message: '请选择一条数据',
          type: "error"
        });
        return
      }
      this.objectMatchList=[]
      // console.log(this.needData,'needData')
      let params1 = {
        verificationStatus: 0,//匹配收款状态
        pageIndex: 1,//页码
        pageSize: 40,//每页条数
      }
      let params = {
        // invoiceStatus: undefined,//发票匹配状态
        // conclusionStatus: undefined,//审结状态
        // filingStartDate: undefined,//归档开始日期
        // filingEndDate: undefined,//归档结束日期
        verificationStatus: 1,//匹配收款状态
        pageIndex: 1,//页码
        pageSize: 40,//每页条数
      }
      this.objectData(params1)
      this.objectData(params)
      this.dialog.matchObjectDialog.show = true;
    },
    //上传匹配项目
    addSubmitObject () {
      this.$refs.objectFormMoreRef.validate(valid => {
        if (valid) {
          let params = {
            drProjectId: this.objectFormMore.objectId,//项目ID
            matchAmount: (+this.objectFormMore.matchAmount)*100,//匹配金额
            revenuePaymentsId: this.needData.id//收入凭证账单ID
          }
          if (this.objectFormMore.objectId) {
            this.addLoading = true
            matchRevenuePaymentByProject(params).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.message,
                  type: "success"
                });
                this.getDataList();
                this.objectClosedDialog()
                this.$refs.objectFormMoreRef.clearValidate();
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error"
                });
                this.$refs.objectFormMoreRef.clearValidate();
              }
              this.addLoading = false
            })
          }
        }
      });
    },
    rowClass ({ columnIndex }) {
      return 'padding:8px!important;'
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange (val) {
      this.pages.pageSize = val;
      this.getDataList();
    },
    async init () {
      this.getDataList()
    }
  }
}
</script>

<style scoped>

</style>
